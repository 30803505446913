body {
  margin: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
header {
  padding-bottom: 0.3125rem;
}
article {
  border: 0.0625rem solid #aaa;
  text-align: left;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 1);
}
#App {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: rgba(232, 232, 232, 0.85);
  width: 90%;
  max-width: 66rem;
  padding: 0.3125rem;
  border: 0.0625rem solid black;
}
#portrait {
  border-radius: 50%;
  border: 0.0625rem solid rgba(0, 0, 0, 0.25);
}
#sports_teams {
  padding-top: 0.625rem;
}
.social_icons {
  height: 1.875rem;
  width: 1.875rem;
  border: 0;
  border-radius: 50%;
  margin: 0.125rem;
}
#twitter_embed {
  margin: auto;
  width: 70%;
  padding-top: 0.9375rem;
}
#links_item {
  padding: 0.375rem;
}
#map {
  height: 30rem;
  margin: 0.25rem 0;
}
#grid_section {
  text-align: center;
  width: 100%;
  margin: auto;
}
#text_list_section {
  padding: 0 0.5rem;
}
.flag {
  border: 0.0625rem solid black;
  display: block;
  margin: 0 auto;
}
.map_flag {
  height: 6rem !important;
  width: 8rem !important;
  display: block !important;
}
.grid_flag_list {
  padding: 0.125rem 0;
  font-size: 0.5rem;
}
.grid_flag {
  height: 3rem !important;
  width: 4rem !important;
  display: block !important;
}
.gm-style-iw button:focus {
  outline: 0;
}
.gm-style-iw-chr {
  height: 2rem;
}
